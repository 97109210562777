import rasmusApiUrl from "./constants";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { format, isDate } from "date-fns";

export interface RasmusApiResponse {
  success: boolean;
  message?: string;
  data?: {
    redirectUrl?: string;
  };
}

export const rasmusApi: AxiosInstance = axios.create({
  baseURL: rasmusApiUrl,
  method: "post",
});

export const rasmusRequest = async <T>(
  data: T
): Promise<AxiosResponse<RasmusApiResponse>["data"]> => {
  const response = await rasmusApi({
    data,
  });

  return response.data;
};

export const redirectToPath = (path: string | undefined) => {
  const baseUrl = window.location.origin;
  const redirectUrl = baseUrl + (path ?? "");
  window.location.href = redirectUrl;
};

export const apiDateFormat = (value: string): string => {
  const date = new Date(value);

  if (isDate(date)) {
    return format(date, "dd.MM.yyyy");
  } else {
    return "";
  }
};

export const checkPasswordComplexity = (val: string): boolean => {
  const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-._!"`'#%&,:;<>=@{}~$()*+/?\\[\]^|]).{8,}/;
  return regex.test(val);
};

export const getTranslatedErrorForMessage = (
  message: string | undefined
): string => {
  switch (message) {
    case "User not found":
      return "Es existiert kein Benutzer zu dieser E-Mail Adresse.";
    case "Invalid credentials":
      return "E-Mail Adresse oder Passwort sind falsch, bitte versuchen Sie es erneut.";
    case "Password missmatch":
      return "Die angegebenen Passwörter stimmen nicht überein.";
    case "Hash validation failed":
      return "Der von Ihnen genutzte Link scheint nicht mehr gültig zu sein. Bitte fordern sie das Zurücksetzen ihres Passwortes erneut an.";
    case "Hash expired":
      return "Der von Ihnen genutzte Link scheint nicht mehr gültig zu sein. Bitte fordern sie das Zurücksetzen ihres Passwortes erneut an.";
    case "Old password not valid":
      return "Sie haben Ihr altes Passwort falsch eingegeben. Bitte versuchen Sie es erneut.";
    case "Missing parameters":
      return "Es fehlen Daten. Bitte prüfen Sie Ihre Eingabe.";
    default:
      return "Da ist etwas schiefgelaufen. Bitte versuchen Sie es später erneut.";
  }
};
