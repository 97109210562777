import { HOST } from "@/constants";

export const RASMUS_CONFIG = {
  baseUrl: `${HOST}/rasmus-club/api`,
};

export const rasmusApiUrl = RASMUS_CONFIG.baseUrl;

export const genderSelectOptions = [
  {
    label: "Weiblich",
    value: "W",
  },
  {
    label: "Männlich",
    value: "M",
  },
];

export const genderRadioOptions = [
  {
    title: "Junge",
    value: "M",
  },
  {
    title: "Mädchen",
    value: "W",
  },
];

export default rasmusApiUrl;
